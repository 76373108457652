import React, { useEffect, useState } from 'react'
import { Card, CardBody } from '@paljs/ui/Card'
import { ButtonLink } from '@paljs/ui/Button'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import SEO from '../components/SEO'
import Loading from '../components/Loading'
import Layout from '../layout'

const ErrorStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  small {
    margin-bottom: 3rem;
  }
  h1 {
    margin-bottom: 0.5rem;
  }
  a {
    max-width: 20rem;
  }
`
const Error: React.FC = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 700)
  }, [])

  if (loading) return <Loading isAuth={true} />
  return (
    <Layout isAuth>
      <SEO title='404 Page Not Found' />
      <Card>
        <CardBody>
          <ErrorStyle>
            <h1>404 Page Not Found</h1>
            <small>The page you were looking for doesn&apos;t exist</small>
            <ButtonLink fullWidth appearance='hero' onClick={() => navigate('/')} shape='Rectangle'>
              Take me home
            </ButtonLink>
          </ErrorStyle>
        </CardBody>
      </Card>
    </Layout>
  )
}

export default Error
